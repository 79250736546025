import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

import { db } from "./firebase";
import { SubscriptionPayment } from "../types/subscriptionPayment.type";

const COLLECTION = 'subscriptionPayments'

const getSubscriptionPaymentsByUser = async (userId: string, pageSize: number, lastDoc: any): Promise<{ data: SubscriptionPayment[], lastDoc: any, firstDoc: any }> => {
  let queryRef = query(
    collection(db, COLLECTION),
    where('userId', '==', userId),
    orderBy('createdAt','desc'),
    limit(pageSize)
  );

  if (lastDoc) {
    queryRef = query(queryRef, startAfter(lastDoc));
  }

  const querySnapshot = await getDocs(queryRef);

  if (querySnapshot.empty) {
    return { data: [], lastDoc: null, firstDoc: null };
  }

  const subscriptionPayments = querySnapshot.docs.map((doc) => {
    return doc.data() as SubscriptionPayment;
  });

  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  const firstVisible = querySnapshot.docs[0];

  return { data: subscriptionPayments, lastDoc: lastVisible, firstDoc: firstVisible };
}

const getPaymentSubscriptionCount = async (userId: string): Promise<number> => {
  const querySnapshot = await getDocs(query(collection(db, COLLECTION), where('userId', '==', userId)));
  return querySnapshot.size;
}

export const PaymentService = {
  getSubscriptionPaymentsByUser,
  getPaymentSubscriptionCount
};
