import React from "react";

interface CardProps {
  title?: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ title, children }) => {
  return (
    <div className="rounded border bg-slate-100 p-4">
      {title && <div className="mb-4 text-lg font-bold">{title}</div>}
      <div className="text-base">{children}</div>
    </div>
  );
};

export default Card;
