import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { Invoice } from "../interfaces/invoice.interface";

const COLLECTION = "invoices";

const getById = async ({ invoiceId }: { invoiceId: string }) => {
  const q = query(collection(db, COLLECTION), where("id", "==", invoiceId));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    throw new Error("invoice-not-found");
  }

  const docSnap = querySnapshot.docs[0];

  const data = docSnap.data();

  data.totalAmount = data.totalAmountInCents ?? data.totalAmount * 100;

  return data as Invoice;
};

export const InvoiceService = {
  getById,
};
