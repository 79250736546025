import UserOneTimePaymentList from "../../../components/lists/UserOneTimePaymentList";
import UserSubscriptionPaymentList from "../../../components/lists/UserSubscriptionPaymentList";
import Title from "../../../components/shared/Title";

const PaymentsView = ({ profileId }: { profileId: string }) => {
  return (
    <div className="flex flex-col gap-4">
      <Title>One Time Payments</Title>
      <UserOneTimePaymentList userId={profileId} />
      <Title>Subscription Payments</Title>
      <UserSubscriptionPaymentList userId={profileId} />
    </div>
  );
};

export default PaymentsView;
