import { useEffect, useState } from "react";
import { TablePagination } from "../shared/Table/TablePagination";
import useFetchReferralProgramProfilesCount from "../../hooks/useFetchReferralProgramProfilesCount";

type ReferralProgramTablePaginationProps = {
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (page: number) => unknown;
  referredBy?: string;
};

export const ReferralProgramProfileTablePagination = ({
  currentPage,
  itemsPerPage,
  onPageChange,
  referredBy,
}: ReferralProgramTablePaginationProps) => {
  const fetchReferralProgramProfilesCount = useFetchReferralProgramProfilesCount();
  const [referralProgramProfilesCount, setReferralProgramProfilesCount] = useState<number>(0);

  useEffect(() => {
    fetchReferralProgramProfilesCount({ referredBy }).then((result) => {
      setReferralProgramProfilesCount(result.data().count);
    });
  }, [fetchReferralProgramProfilesCount, referredBy]);

  return (
    <>
      <TablePagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        onPageChange={onPageChange}
        recordsCount={referralProgramProfilesCount}
      />
    </>
  );
};
