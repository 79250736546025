import { Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import InvoicesIndexPage from "../pages/invoices/InvoicesIndexPage";
import InvoicesShowPage from "../pages/invoices/InvoicesShowPage";

export const InvoicesRoutes = (
  <>
    <Route
      path={routeNames["invoices.index"]}
      element={
        <ProtectedRoute>
          <InvoicesIndexPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["invoices.show"](":id")}
      element={
        <ProtectedRoute>
          <InvoicesShowPage />
        </ProtectedRoute>
      }
    />
  </>
);
