import { useEffect, useState } from "react";
import useFetchKnocksCount from "../../../hooks/useFetchKnocksCount";
import { TablePagination } from "../../shared/Table/TablePagination";
import { KnockStatus } from "../../../types";

type KnocksTablePaginationProps = {
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (page: number) => unknown;
  status?: KnockStatus;
};

export const KnocksTablePagination = ({
  currentPage,
  itemsPerPage,
  onPageChange,
  status,
}: KnocksTablePaginationProps) => {
  const fetchKnocksCount = useFetchKnocksCount();
  const [knocksCount, setKnocksCount] = useState<number>(0);

  useEffect(() => {
    fetchKnocksCount({ status }).then((result) => setKnocksCount(result.data().count));
  }, [fetchKnocksCount, status]);

  return (
    <>
      <TablePagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        onPageChange={onPageChange}
        recordsCount={knocksCount}
      />
    </>
  );
};
