type TablePaginationProps = {
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  recordsCount: number;
};

export const TablePagination = ({
  currentPage,
  itemsPerPage,
  onPageChange,
  recordsCount,
}: TablePaginationProps) => {
  const totalPages = Math.ceil(recordsCount / itemsPerPage);
  const canGoBack = currentPage > 0;
  const canGoForward = currentPage + 1 < totalPages;

  if (totalPages < 2) return null;

  return (
    <div className="flex justify-center">
      <div className="btn-group grid grid-cols-2">
        <button
          className="btn-outline btn-sm btn"
          disabled={!canGoBack}
          onClick={() => onPageChange(currentPage - 1)}
        >
          Previous
        </button>
        <button
          className="btn-outline btn-sm btn"
          disabled={!canGoForward}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};
