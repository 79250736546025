import { useCallback } from "react";
import { db } from "../services/firebase";
import {
  where,
  getCountFromServer,
  query,
  collection,
  CollectionReference,
  QueryConstraint,
} from "firebase/firestore";
import { Invoice } from "../interfaces/invoice.interface";

const COLLECTION = "invoices";

export default function useFetchInvoicesCount() {
  return useCallback(({ userId }: { userId?: string }) => {
    const collectionRef = collection(db, COLLECTION) as CollectionReference<Invoice>;

    const whereConstraints: QueryConstraint[] = [];

    if (userId) {
      whereConstraints.push(where("userId", "==", userId));
    }

    const constraints: QueryConstraint[] = [...whereConstraints];

    return getCountFromServer(query(collectionRef, ...constraints));
  }, []);
}
