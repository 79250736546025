import { DocumentSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DEFAULT_ITEMS_PER_PAGE } from "../../constants";
import Table, { Column } from "../shared/Table/Table";
import useFetchInvoices from "../../hooks/useFetchInvoices";
import { TablePagination } from "../shared/Table/TablePagination";
import useFetchInvoicesCount from "../../hooks/useFetchInvoicesCount";
import { Link } from "react-router-dom";

type InvoicesListProps = {
  userId?: string;
};
interface InvoiceList {
  id: string;
  name: string;
  totalAmount: number;
  createdAt: number;
  businessName?: string;
  customerName?: string;
}

export const InvoicesList = ({ userId }: InvoicesListProps) => {
  const [page, setPage] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState<number>(0);
  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());
  const itemsPerPage = DEFAULT_ITEMS_PER_PAGE;

  const invoicesFetch = useFetchInvoices({
    userId,
    params: {
      cursor: cursors.current.get(page),
      itemsPerPage,
    },
  });

  const fetchKnocksCount = useFetchInvoicesCount();

  useEffect(() => {
    fetchKnocksCount({ userId }).then((result) => setInvoiceCount(result.data().count));
  }, [fetchKnocksCount, userId]);

  const invoices: InvoiceList[] = useMemo(() => {
    return (
      invoicesFetch.data?.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name,
          totalAmount: data.totalAmountInCents ?? data.totalAmount * 100,
          createdAt: data.createdAt,
          businessName: data.business.fullName,
          customerName: data.customer.fullName,
        };
      }) ?? []
    );
  }, [invoicesFetch.data]);

  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        cursors.current.set(page + 1, invoicesFetch.data.docs[invoicesFetch.data.docs.length - 1]);

        return nextPage;
      });
    },
    [invoicesFetch.data],
  );

  const columns: Column<InvoiceList>[] = [
    { header: "ID", accessor: "id", hidden: true },
    { header: "Name", accessor: "name" },
    { header: "Amount", accessor: "totalAmount", formatting: "currency" },
    { header: "Created at", accessor: "createdAt", formatting: "date" },
    { header: "Business", accessor: "businessName" },
    { header: "Customer", accessor: "customerName" },
  ];

  const actionCell = (row: InvoiceList) => {
    return (
      <div className="flex gap-2">
        <a
          href={`${process.env.REACT_APP_WEB_APP_URL}/invoices/${row.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-default btn-outline btn-xs btn"
        >
          Preview
        </a>
        <Link to={`/invoices/${row.id}`} className="btn-default btn-primary btn-xs btn">
          Show
        </Link>
      </div>
    );
  };

  return (
    <div className="mt-4 flex flex-col gap-4">
      {invoicesFetch.status === "loading" && <p>Loading...</p>}
      {invoicesFetch.status === "error" && <p>Error</p>}
      {invoicesFetch.status === "success" && invoices.length === 0 && <p>No invoices found</p>}
      {invoices.length > 0 && (
        <>
          <Table columns={columns} data={invoices} actionCell={actionCell} />
          <TablePagination
            currentPage={page}
            itemsPerPage={itemsPerPage}
            onPageChange={onPageChanged}
            recordsCount={invoiceCount}
          />
        </>
      )}
    </div>
  );
};
