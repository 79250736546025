import { HiOutlineBellAlert } from "react-icons/hi2";
import {
  BiAlarmExclamation,
  BiBlock,
  BiCategoryAlt,
  BiCheckDouble,
  BiCheckboxChecked,
  BiData,
  BiLogOut,
  BiGroup,
  BiSearchAlt,
  BiGift,
  BiCalendarCheck,
  BiImage,
  BiBrain,
  BiDollarCircle,
  BiBook,
} from "react-icons/bi";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import { routeNames } from "../../../routes";
import { logout } from "../../../services/firebase";

export const AppSidebar = () => {
  return (
    <aside className="bg-dark-blue w-60 text-gray-200">
      <div className="z-20 sticky top-0 items-center gap-2 px-4 py-4 hidden lg:flex">
        <div className="font-title text-lg transition-all duration-200 md:text-3xl">
          <span className="lowercase font-bold">dingdoor</span>
        </div>
      </div>
      <div className="">
        <ul className="menu menu-compact flex flex-col p-0 px-2">
          <li className="bg-dark-blue"></li>
          <li className="menu-title">
            <span>Requests</span>
          </li>
          <li>
            <Link
              to={routeNames["knocks.index.searching"]}
              className="flex gap-4"
            >
              <span className="flex-none">
                <BiSearchAlt size={20} />
              </span>{" "}
              <span className="flex-1">Searching</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["knocks.index.active"]} className="flex gap-4">
              <span className="flex-none">
                <BiCheckboxChecked size={20} />
              </span>{" "}
              <span className="flex-1">Active</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["knocks.index.closed"]} className="flex gap-4">
              <span className="flex-none">
                <BiCheckDouble size={20} />
              </span>{" "}
              <span className="flex-1">Closed</span>{" "}
            </Link>
          </li>
          <li>
            <Link
              to={routeNames["knocks.index.expired"]}
              className="flex gap-4"
            >
              <span className="flex-none">
                <BiAlarmExclamation size={20} />
              </span>{" "}
              <span className="flex-1">Expired</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["knocks.index.all"]} className="flex gap-4">
              <span className="flex-none">
                <BiData size={20} />
              </span>{" "}
              <span className="flex-1">All</span>{" "}
            </Link>
          </li>
        </ul>

        <ul className="menu menu-compact flex flex-col p-0 px-2">
          <li className="bg-dark-blue"></li>
          <li className="menu-title">
            <span>General</span>
          </li>
          <li>
            <Link to={routeNames["profiles.index"]} className="flex gap-4">
              <span className="flex-none">
                <BiData size={20} />
              </span>{" "}
              <span className="flex-1">Users</span>
            </Link>
          </li>
          <li>
            <Link to={routeNames["categories.index"]} className="flex gap-4">
              <span className="flex-none">
                <BiCategoryAlt size={20} />
              </span>{" "}
              <span className="flex-1">Categories</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["subscriptions.index"]} className="flex gap-4">
              <span className="flex-none">
                <BiCalendarCheck size={20} />
              </span>{" "}
              <span className="flex-1">Subscriptions</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["invoices.index"]} className="flex gap-4">
              <span className="flex-none">
                <BiData size={20} />
              </span>{" "}
              <span className="flex-1">Invoices</span>
            </Link>
          </li>
          <li>
            <Link
              to={routeNames["promotionalBanners.index"]}
              className="flex gap-4"
            >
              <span className="flex-none">
                <BiImage size={20} />
              </span>{" "}
              <span className="flex-1">Promotional Banners</span>{" "}
            </Link>
          </li>
        </ul>
        <ul className="menu menu-compact flex flex-col p-0 px-2">
          <li className="bg-dark-blue"></li>
          <li className="menu-title">
            <span>Posts</span>
          </li>
          <li>
            <Link to={routeNames["posts.index.pending"]} className="flex gap-4">
              <span className="flex-none">
                <HiOutlineBellAlert size={20} />
              </span>{" "}
              <span className="flex-1">Pending</span>{" "}
            </Link>
          </li>
          <li>
            <Link
              to={routeNames["posts.index.approved"]}
              className="flex gap-4"
            >
              <span className="flex-none">
                <FiThumbsUp size={20} />
              </span>{" "}
              <span className="flex-1">Approved</span>{" "}
            </Link>
          </li>
          <li>
            <Link
              to={routeNames["posts.index.rejected"]}
              className="flex gap-4"
            >
              <span className="flex-none">
                <FiThumbsDown size={20} />
              </span>{" "}
              <span className="flex-1">Rejected</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["posts.index.blocked"]} className="flex gap-4">
              <span className="flex-none">
                <BiBlock size={20} />
              </span>{" "}
              <span className="flex-1">Blocked</span>{" "}
            </Link>
          </li>
        </ul>
        <ul className="menu menu-compact flex flex-col p-0 px-2">
          <li className="bg-dark-blue"></li>
          <li className="menu-title">
            <span>Programs</span>
          </li>
          <li>
            <Link
              to={routeNames["programs.index.referral"]}
              className="flex gap-4"
            >
              <span className="flex-none">
                <BiGroup size={20} />
              </span>{" "}
              <span className="flex-1">Referral Program</span>{" "}
            </Link>
          </li>
          <li>
            <Link
              to={routeNames["gift-card-requests.index"]}
              className="flex gap-4"
            >
              <span className="flex-none">
                <BiGift size={20} />
              </span>{" "}
              <span className="flex-1">Gift Card Requests</span>{" "}
            </Link>
          </li>
        </ul>
        <ul className="menu menu-compact flex flex-col p-0 px-2">
          <li className="bg-dark-blue"></li>
          <li className="menu-title">
            <span>AI Features</span>
          </li>
          <li>
            <Link to={routeNames["ai.assistant"]} className="flex gap-4">
              <span className="flex-none">
                <BiBrain size={20} />
              </span>{" "}
              <span className="flex-1">Assistant</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["ai.prices"]} className="flex gap-4">
              <span className="flex-none">
                <BiDollarCircle size={20} />
              </span>{" "}
              <span className="flex-1">Price Prediction</span>{" "}
            </Link>
          </li>
        </ul>

        <ul className="menu menu-compact flex flex-col p-0 px-2">
          <li className="bg-dark-blue"></li>
          <li className="menu-title">
            <span>Knowledge Bases</span>
          </li>
          <li>
            <Link to={routeNames["ai.knowledge"]} className="flex gap-4">
              <span className="flex-none">
                <BiBook size={20} />
              </span>{" "}
              <span className="flex-1">Questions and Answers</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["ai.related"]} className="flex gap-4">
              <span className="flex-none">
                <BiBook size={20} />
              </span>{" "}
              <span className="flex-1">Requests Relatedness</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["ai.priceKnowledge"]} className="flex gap-4">
              <span className="flex-none">
                <BiBook size={20} />
              </span>{" "}
              <span className="flex-1">Requests Prices</span>{" "}
            </Link>
          </li>
        </ul>

        <ul className="menu menu-compact flex flex-col p-0 px-2">
          <li className="bg-dark-blue"></li>
          <li className="menu-title">
            <span>Knowledge Bases</span>
          </li>
          <li>
            <Link to={routeNames["ai.knowledge"]} className="flex gap-4">
              <span className="flex-none">
                <BiBook size={20} />
              </span>{" "}
              <span className="flex-1">Questions and Answers</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["ai.related"]} className="flex gap-4">
              <span className="flex-none">
                <BiBook size={20} />
              </span>{" "}
              <span className="flex-1">Requests Relatedness</span>{" "}
            </Link>
          </li>
          <li>
            <Link to={routeNames["ai.priceKnowledge"]} className="flex gap-4">
              <span className="flex-none">
                <BiBook size={20} />
              </span>{" "}
              <span className="flex-1">Requests Prices</span>{" "}
            </Link>
          </li>
        </ul>
        <ul className="menu menu-compact flex flex-col p-0 px-2">
          <li className="bg-dark-blue"></li>
          <li>
            <div
              className="flex gap-4"
              onClick={() => {
                logout();
                window.location.reload();
              }}
            >
              <span className="flex-none">
                <BiLogOut size={20} />
              </span>{" "}
              <span className="flex-1">Logout</span>{" "}
            </div>
          </li>
        </ul>
      </div>
    </aside>
  );
};
