const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formattedCurrency = (amount: number) => {
  return currencyFormatter.format(amount / 100);
};

export const toCents = (amount: string) => {
  return Math.round(parseFloat(amount) * 100);
};
