import { useEffect, useRef, useState } from "react";
import { PostService } from "../../../services";
import { ProfileService } from "../../../services/profile.service";
import { Chat, Post, Profile, ServiceRequest } from "../../../types";
import { Modal } from "../../shared/Modal/Modal";
import useToggle from "../../../hooks/useToggle";
import { BiChat } from "react-icons/bi";
import { ChatService } from "../../../services/chat.service";
import { ChatMessagesList } from "../../lists/ChatMessagesList";
import { DismissedChatIndicator } from "../../chats/DismissedChatIndicator";
import { ChatCallAttempts } from "../../chats/ChatCallAttempts";
import { ProfileLink } from "../../shared/Navigation/ProfileLink";

type ServiceRequestListItemParams = {
  serviceRequest: ServiceRequest;
  viewType: "customer" | "provider";
};

export const ServiceRequestListItem = ({
  serviceRequest,
  viewType,
}: ServiceRequestListItemParams) => {
  const [post, setPost] = useState<Post | null>();
  const [hasError, setHasError] = useState(false);
  const [profile, setProfile] = useState<Profile>();
  const [chat, setChat] = useState<Chat>();
  const [showModal, setShowModal] = useToggle(false);
  const chatCallAttemptsRef = useRef<{ [key: string]: JSX.Element }>({});

  useEffect(() => {
    const getPostAndProfile = async () => {
      if (serviceRequest) {
        try {
          PostService.getById(serviceRequest.postId).then((post) => setPost(post));

          ProfileService.getById(serviceRequest.userId).then((profile) => setProfile(profile));

          if (serviceRequest.status === "accepted") {
            const chat = await ChatService.get({
              knockId: serviceRequest.knockId,
              providerId: serviceRequest.userId,
            });

            if (chat) {
              setChat(chat);
            }
          }
        } catch (error) {
          setHasError(true);
          console.error(error);
        }
      }
    };

    getPostAndProfile();
  }, [serviceRequest]);

  const getChatCallAttempts = (chatId: string) => {
    if (!chatCallAttemptsRef.current[chatId]) {
      chatCallAttemptsRef.current[chatId] = <ChatCallAttempts chatId={chatId} />;
    }
    return chatCallAttemptsRef.current[chatId];
  };

  if (post === null || hasError) {
    return <> </>;
  }

  if (post === undefined || profile === undefined) {
    return (
      <tr data-service-request-id={serviceRequest.id}>
        <td>Loading...</td>
      </tr>
    );
  }

  const statusClass = (status: string) => {
    switch (status) {
      case "accepted":
        return "badge-success";
      case "rejected":
        return "badge-error";
    }
  };

  const name = `${profile.name} ${profile.lastName}`;

  return (
    <>
      <Modal show={showModal} onCloseModal={() => setShowModal.set(false)} title="Chat messages">
        {chat && <ChatMessagesList chat={chat!} />}
      </Modal>
      <tr className="flex flex-col lg:table-row">
        <td className="whitespace-normal">
          <div className="font-bold">{post.businessName}</div>
          {viewType === "customer" && <ProfileLink userId={profile.id} fullName={name} />}
          {viewType === "provider" && chat && (
            <ProfileLink userId={chat.customerId} fullName={chat.customerName} />
          )}
          <div>
            {serviceRequest.isExpressConnection ? (
              <span className="badge-primary badge">Express</span>
            ) : (
              ""
            )}
          </div>
          <div className="text-xs font-normal lg:hidden">
            <div>{profile.email}</div>
            <div>{profile.phoneNumber}</div>
            {serviceRequest.status === "accepted" && chat && (
              <div className="mt-2">{getChatCallAttempts(chat.id)}</div>
            )}
            <div className="mt-2 flex items-center">
              <span className={`badge ${statusClass(serviceRequest.status)}`}>
                {serviceRequest.status}
              </span>

              {serviceRequest.status === "accepted" && (
                <button className="btn-xs btn ml-2" onClick={() => setShowModal.toggle()}>
                  <BiChat className="mr-1 cursor-pointer" />
                  Chat
                </button>
              )}
            </div>
          </div>
        </td>
        <td className="hidden lg:table-cell">
          <p>{profile.email}</p>
          <p>{profile.phoneNumber}</p>
        </td>
        <td className="hidden lg:table-cell">
          <span className={`badge ${statusClass(serviceRequest.status)}`}>
            {serviceRequest.status}
          </span>
        </td>
        <td className="hidden lg:table-cell">
          {serviceRequest.status === "accepted" && chat && getChatCallAttempts(chat.id)}
        </td>
        <td className="hidden lg:table-cell">
          {serviceRequest.status === "accepted" && (
            <div className="flex items-center gap-2">
              <span className="cursor-pointer rounded-full bg-info p-1 text-white">
                <BiChat className="cursor-pointer" onClick={() => setShowModal.toggle()} />
              </span>
              {chat?.isDismissed && <DismissedChatIndicator />}
            </div>
          )}
        </td>
      </tr>
    </>
  );
};
