import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

import { db } from "./firebase";
import { UserPayment } from "../types/userPayment.type";

const COLLECTION = 'userPayments'
const SUB_COLLECTION = 'payments'

const getOneTimePaymentsByUser = async (userId: string, pageSize: number, lastDoc: any): Promise<{ data: UserPayment[], lastDoc: any, firstDoc: any }> => {
  let queryRef = query(
    collection(db, `${COLLECTION}/${userId}/${SUB_COLLECTION}`),
    orderBy('createdAt','desc'),
    limit(pageSize)
  );

  if (lastDoc) {
    queryRef = query(queryRef, startAfter(lastDoc));
  }

  const querySnapshot = await getDocs(queryRef);

  if (querySnapshot.empty) {
    return { data: [], lastDoc: null, firstDoc: null };
  }

  const oneTimePayments = querySnapshot.docs.map((doc) => {
    return doc.data() as UserPayment;
  });

  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  const firstVisible = querySnapshot.docs[0];

  return { data: oneTimePayments, lastDoc: lastVisible, firstDoc: firstVisible };
}

const getOneTimePaymentCount = async (userId: string): Promise<number> => {
  const querySnapshot = await getDocs(collection(db, `${COLLECTION}/${userId}/${SUB_COLLECTION}`));
  return querySnapshot.size;
}

export const OneTimePaymentService = {
  getOneTimePaymentsByUser,
  getOneTimePaymentCount
};
