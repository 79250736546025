import { useEffect, useState } from "react";
import { ServiceRequestService } from "../../../services";
import { ServiceRequest } from "../../../types";
import { ServiceRequestListItem } from "../ServiceRequestListItem";
import { BiInfoCircle } from "react-icons/bi";

type ServiceRequestListParams = {
  knockId?: string;
  userId?: string;
};

export const ServiceRequestList = ({
  knockId,
  userId,
}: ServiceRequestListParams) => {
  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>();

  useEffect(() => {
    const unsubscribe = ServiceRequestService.queryServiceRequestsSubscription({
      onDataChange: (serviceRequests: ServiceRequest[]) => {
        setServiceRequests(
          serviceRequests.sort((a, b) => {
            return a.status.localeCompare(b.status);
          })
        );
      },
      knockId,
      userId,
    });

    return () => {
      unsubscribe();
    };
  }, [knockId, userId]);

  if (serviceRequests === undefined) {
    return <>Loading...</>;
  }

  return (
    <>
      <div className="lg:overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr className="flex w-full lg:table-row">
              <th className="flex-1">Business</th>
              <th className="hidden lg:table-cell">Contact</th>
              <th>
                <span className="hidden lg:table-cell">Status</span>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {serviceRequests.length === 0 ? (
              <tr>
                <td colSpan={5}>
                  <p>
                    <BiInfoCircle className="mr-2 inline" />
                    There are no service requests
                  </p>
                </td>
              </tr>
            ) : (
              <>
                {serviceRequests!.map((serviceRequest: ServiceRequest) => {
                  return (
                    <ServiceRequestListItem
                      key={`service-request-${serviceRequest.id}`}
                      serviceRequest={serviceRequest}
                      viewType={userId === undefined ? "customer" : "provider"}
                    />
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
