export type KnockStatus = "searching" | "active" | "closed" | "expired";

export type KnockChannel = "web" | "app" | "landing";

export type KnockFlow =
  | "aiAssisted"
  | "pickCategory"
  | "resendRequest"
  | "guestAiAssisted"
  | "guestPickCategory";

export const knockFlowMapping: { [key in KnockFlow]: string } = {
  aiAssisted: "AI Assisted",
  pickCategory: "Pick Category",
  resendRequest: "Resend Request",
  guestAiAssisted: "AI Assisted (Guest mode)",
  guestPickCategory: "Pick Category (Guest mode)",
};

export type Knock = {
  acceptedRequestsCount?: number;
  addressMainText: string;
  addressSecondaryText: string;
  categoryId: string;
  channel: KnockChannel;
  chatsCount: number;
  createdAt: Date;
  customerName: string;
  description: string;
  estimatedCost?: number;
  flow: KnockFlow;
  id: string;
  isAcceptingCalls: boolean;
  interactionId?: string;
  lat: number;
  lng: number;
  matched?: boolean;
  status: KnockStatus;
  updatedAt: Date;
  userId: string;
};
