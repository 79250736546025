import { useState } from "react";
import { BiCopy } from "react-icons/bi";

export const TableLinkCell = ({ url }: { url: string }) => {
  const [showToast, setShowToast] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 2000);
    });
  };

  return (
    <>
      <a href={url} className="underline" target="_blank" rel="noreferrer">
        Visit link
      </a>
      <button onClick={handleCopy} className="ml-4 rounded border p-1">
        <BiCopy />
      </button>
      {showToast && (
        <div className="toast-end toast">
          <div className="alert alert-success">Copied to clipboard!</div>
        </div>
      )}
    </>
  );
};
