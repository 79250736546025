import { useCallback, useEffect, useState } from "react";
import { Modal } from "../../components/shared/Modal/Modal";
import { getAssistantInteraction } from "../../services";
import { AssistantInteraction } from "../../types";

interface AssistantInteractionProps {
  show: boolean;
  knockId: string;
  onCloseModal: () => void;
}

const AssistantInteractionView = ({ show, knockId, onCloseModal }: AssistantInteractionProps) => {
  const [loading, setLoading] = useState(false);
  const [interaction, setInteraction] = useState<AssistantInteraction | null>(null);

  const fetchInteraction = useCallback(async () => {
    setLoading(true);
    const interaction = await getAssistantInteraction(knockId);
    setLoading(false);
    console.log(interaction);
    setInteraction(interaction);
  }, [knockId]);

  useEffect(() => {
    if (show) {
      fetchInteraction();
    }
  }, [show, fetchInteraction]);

  return (
    <Modal title="Assistant Interaction" show={show} onCloseModal={onCloseModal}>
      <div>
        {loading ? (
          <p className="text-center text-gray-500">Loading...</p>
        ) : interaction ? (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 rounded-lg border-2 px-4 py-2">
              <div>
                <p className="text-lg font-bold">STEP 1</p>
                <p className="text-sm font-normal text-gray-500">User describes their request</p>
              </div>
              <div>
                <p className="text-sm">Original request</p>
                <p className="font-semibold">{interaction?.initialRequest}</p>
              </div>
              <div>
                <p className="text-sm">Original Category</p>
                <p className="font-semibold">
                  {interaction?.initialCategoryId ? interaction.initialCategory : "(None)"}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 rounded-lg border-2 px-4 py-2">
              <div>
                <p className="text-lg font-bold">STEP 2</p>
                <p className="text-sm font-normal text-gray-500">
                  User answers the assistant questions
                </p>
              </div>
              {interaction.questionsResponseDetails ? (
                interaction?.questionsResponseDetails.map((question, index) => (
                  <div key={index}>
                    <p className="text-sm">{question.question}</p>
                    <p className="font-semibold">{question.answer}</p>
                  </div>
                ))
              ) : (
                <p className="text-sm">(No data available)</p>
              )}
            </div>
            <div className="flex flex-col gap-4 rounded-lg border-2 px-4 py-2">
              <div>
                <p className="text-lg font-bold">STEP 3</p>
                <p className="text-sm font-normal text-gray-500">
                  Assistant infers category and enhances the request
                </p>
              </div>
              <div>
                <p className="text-sm">Inferred category</p>

                {interaction?.requestEnhancedInferredCategory ? (
                  <p className="font-semibold">
                    {interaction?.requestEnhancedInferredCategory.categoryName}
                  </p>
                ) : (
                  <p className="text-sm">(No data available)</p>
                )}
              </div>
              <div>
                <p className="text-sm">Enhanced request</p>
                {interaction?.requestEnhanced ? (
                  <p className="font-semibold">{interaction.requestEnhanced}</p>
                ) : (
                  <p className="text-sm">(No data available)</p>
                )}
              </div>
            </div>
            {interaction?.requestEnhanced !== interaction?.requestDescription ? (
              <div className="flex flex-col gap-4 rounded-lg border-2 px-4 py-2">
                <div>
                  <p className="text-lg font-bold">STEP 4</p>
                  <p className="text-sm font-normal text-gray-500">
                    User may edit the request or confirm the assistant's suggestion
                  </p>
                </div>
                <div>
                  <p className="text-sm">User final category</p>
                  <p className="font-semibold">{interaction?.requestCategory}</p>
                </div>
                <div>
                  <p className="text-sm">User final request</p>
                  <p className="font-semibold">{interaction?.requestDescription}</p>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <p>No interaction found</p>
        )}
      </div>
    </Modal>
  );
};

export default AssistantInteractionView;
