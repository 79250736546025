import {
  collection,
  DocumentSnapshot,
  limit,
  orderBy,
  query,
  QueryConstraint,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { useFirestoreCollection } from "reactfire";

type useFetchInvoicesParams = {
  userId?: string;
  params: {
    cursor?: DocumentSnapshot;
    itemsPerPage: number;
  };
};

const COLLECTION = "invoices";

const useFetchInvoices = ({ userId, params }: useFetchInvoicesParams) => {
  const orderConstraints: QueryConstraint[] = [];
  const whereConstraints: QueryConstraint[] = [];

  if (userId) {
    whereConstraints.push(where("userId", "==", userId));
  }
  orderConstraints.push(orderBy("createdAt", "desc"));

  const constraints: QueryConstraint[] = [
    ...whereConstraints,
    ...orderConstraints,
    limit(params.itemsPerPage),
  ];

  if (params.cursor) {
    constraints.push(startAfter(params.cursor));
  }

  const collectionRef = collection(db, COLLECTION);

  const q = query(collectionRef, ...constraints);

  return useFirestoreCollection(q);
};

export default useFetchInvoices;
