import { Link } from "react-router-dom";
import { routeNames } from "../../../routes";

interface profileLinkProps {
  userId: string;
  fullName: string;
}

export const ProfileLink = ({ userId, fullName }: profileLinkProps) => {
  return (
    <Link to={routeNames["profiles.show"](userId)} className="link underline">
      {fullName}
    </Link>
  );
};
