import { useState } from "react";
import Table, { Column } from "../../../components/shared/Table/Table";
import Title from "../../../components/shared/Title";
import { PaymentLink } from "../../../interfaces/invoice.interface";
import { Modal } from "../../../components/shared/Modal/Modal";
import { InvoicePaymentLinkForm } from "./InvoicePaymentLinkForm";
import DeactivateButton from "./InvoicePaymenLinkDeactivate";

const columns: Column<PaymentLink>[] = [
  { header: "ID", accessor: "paymentLinkId", hidden: true },
  { header: "Product Name", accessor: "productName" },
  { header: "Amount", accessor: "amountInCents", formatting: "currency" },
  { header: "Currency", accessor: "currency" },
  { header: "Created At", accessor: "createdAt", formatting: "date" },
  { header: "Url", accessor: "url", formatting: "link" },
];

interface InvoicesPaymentsLinkIndexProps {
  paymenLinks: PaymentLink[];
  invoiceId: string;
  invoiceName: string;
}

const InvoicesPaymentsLinkIndex = ({
  paymenLinks,
  invoiceId,
  invoiceName,
}: InvoicesPaymentsLinkIndexProps) => {
  const [showModal, setShowModal] = useState(false);

  const actionCell = (row: PaymentLink) => (
    <DeactivateButton
      paymentLink={row}
      invoiceId={invoiceId}
      action={row.active ? "deactivate" : "activate"}
    />
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between">
        <Title>Payments Links</Title>
        <button onClick={() => setShowModal(true)} className="btn">
          Create Payment Link
        </button>
      </div>
      <Table columns={columns} data={paymenLinks} actionCell={actionCell} />
      <Modal show={showModal} title="Create paymentLink" onCloseModal={() => setShowModal(false)}>
        <InvoicePaymentLinkForm
          invoiceId={invoiceId}
          invoiceName={invoiceName}
          onBack={() => setShowModal(false)}
        />
      </Modal>
    </div>
  );
};

export default InvoicesPaymentsLinkIndex;
