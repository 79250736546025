import { useEffect, useState } from "react";
import { BiArrowBack, BiBot, BiMap, BiTimer } from "react-icons/bi";
import { Link, useLocation, useParams } from "react-router-dom";
import { ServiceRequestList } from "../../../components/service-requests";
import { FullPageLoader, Map, PageHeader } from "../../../components/shared";
import { routeNames } from "../../../routes";
import { CategoriesService, KnockService, ProfileService } from "../../../services";
import { Category, Knock, knockFlowMapping, Profile } from "../../../types";
import DateTimeDisplay from "../../../ui/dateTimeDisplay";
import TimeAgo from "timeago-react";
import { MINIMUM_KNOCK_COST_FOR_PREFFERED_PROVIDER } from "../../../constants";
import AssistantInteractionView from "../../ai/AssistantInteractionView";

export const KnockShowPage = () => {
  const knockId = useParams()["id"];

  const [knock, setKnock] = useState<Knock>();
  const [category, setCategory] = useState<Category>();
  const [customerProfile, setCustomerProfile] = useState<Profile>();
  const [showAssistantModal, setShowAssistantModal] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const getKnock = async () => {
      if (knockId) {
        setKnock(await KnockService.getById(knockId));
      }
    };

    getKnock();
  }, [knockId]);

  useEffect(() => {
    const getCategoryAndProfile = async () => {
      if (knock) {
        setCategory(await CategoriesService.getById(knock.categoryId));
        setCustomerProfile(await ProfileService.getById(knock.userId));
      }
    };

    getCategoryAndProfile();
  }, [knock]);

  if (category === undefined || knock === undefined || customerProfile === undefined) {
    return <FullPageLoader className="bg-slate-300" />;
  }
  const query = new URLSearchParams(location.search);

  return (
    <>
      <div className="flex items-end">
        <Link to={query.get("referrer") ?? routeNames["knocks.index.all"]}>
          <BiArrowBack size={30} className="mr-2" />
        </Link>
        <PageHeader>{`${category.name} request`}</PageHeader>
      </div>

      <div className="mt-6 grid gap-4 md:grid-cols-2 xl:grid-cols-3">
        <div className="rounded bg-slate-100 p-4">
          <div className="flex flex-col justify-between">
            <div>
              <div className="mb-2 text-xs font-bold uppercase">Customer details</div>
              <div>
                {customerProfile.name} {customerProfile.lastName}
              </div>
              <div className="text-sm">{customerProfile.email}</div>
              <div className="text-sm">{customerProfile.phoneNumber}</div>
              <div className="mt-4 text-sm">
                <span>Accepting calls: </span>
                <b>{knock.isAcceptingCalls ? "Yes" : "No"}</b>
              </div>
              {knock.flow && (
                <div className="text-sm">
                  <span>User flow: </span>
                  <b>{knockFlowMapping[knock.flow]}</b>
                </div>
              )}
              {knock.channel && (
                <div className="text-sm">
                  <span>Channel: </span>
                  <b>{knock.channel}</b>
                </div>
              )}
              {knock.estimatedCost && (
                <div className="text-sm">
                  <span>AI Estimate: </span>
                  <b>${knock.estimatedCost}</b>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="rounded bg-slate-100 p-4">
          <div className="flex h-full flex-col justify-between">
            <div>
              <div className="mb-2 text-xs font-bold uppercase">Service description</div>
              <div>
                {knock?.description}
                {knock?.interactionId && (
                  <>
                    <button
                      onClick={() => setShowAssistantModal(true)}
                      className="btn-link btn px-0"
                    >
                      <BiBot className="mr-1" />
                      Check interaction
                    </button>
                    <AssistantInteractionView
                      show={showAssistantModal}
                      knockId={knock.id}
                      onCloseModal={() => setShowAssistantModal(false)}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="mt-2 flex items-center text-sm">
              <BiTimer className="mr-2" />
              <div>
                <DateTimeDisplay date={knock.createdAt} /> - <TimeAgo datetime={knock.createdAt} />
              </div>
            </div>
          </div>
        </div>

        <div className="rounded bg-slate-100 p-4">
          <div className="mb-2 text-xs font-bold uppercase">Service Details</div>
          <div className="flex">
            <BiMap size={35} className="flex-0 mr-2" />
            <div>
              <div>{knock.addressMainText}</div>
              <div className="text-sm font-bold">{knock.addressSecondaryText}</div>
            </div>
          </div>
          {knock.estimatedCost &&
            knock.estimatedCost > MINIMUM_KNOCK_COST_FOR_PREFFERED_PROVIDER && (
              <p className="mt-4 text-sm">
                <span className="badge-info badge">Preferred</span>
              </p>
            )}
        </div>
      </div>

      <div className="">
        <h2 className="mt-8 mb-4 font-bold">Matching businesses</h2>
        <ServiceRequestList knockId={knock!.id} />
      </div>

      <div className="my-4 xl:w-2/3">
        <Map lat={knock.lat} lng={knock.lng} />
      </div>
    </>
  );
};
