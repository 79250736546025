import { InvoicesList } from "../../../components/lists/InvoicesList";
import Title from "../../../components/shared/Title";

const FinancialDocumentsView = ({ profileId }: { profileId: string }) => {
  return (
    <div className="flex flex-col gap-4">
      <Title>Invoices</Title>
      <InvoicesList userId={profileId} />
    </div>
  );
};

export default FinancialDocumentsView;
