import { InvoicesList } from "../../components/lists/InvoicesList";
import { PageHeader } from "../../components/shared";

const InvoicesIndexPage = () => {
  return (
    <>
      <div className="flex items-end justify-between">
        <PageHeader>Invoices</PageHeader>
      </div>
      <InvoicesList />
    </>
  );
};

export default InvoicesIndexPage;
