import { useEffect, useState, useRef } from "react";
import { TablePagination } from "../shared/Table/TablePagination";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import useProfilesCount from "../../hooks/useProfilesCount";
import { getProfileById, searchProfile } from "../../services/typesense.service";

const ITEMS_PER_PAGE = 10;
const FIREBASE_ID_LENGTH = 28;

interface SearchProfile {
  id: string;
  name: string;
  email?: string;
  phoneNumber?: string;
  lastName?: string;
}

export const ProfilesList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredProfiles, setFilteredProfiles] = useState<SearchProfile[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [page, setPage] = useState(0);
  const [profileCount, setProfileCount] = useState(0);
  const cursors = useRef<Map<number, string>>(new Map());
  const getProfilesCount = useProfilesCount();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoading(true);
      try {
        let profiles: SearchProfile[] = [];

        if (debouncedSearchTerm.length === FIREBASE_ID_LENGTH) {
          profiles.push(await getProfileById(debouncedSearchTerm));
        } else {
          profiles = await searchProfile(debouncedSearchTerm);
        }

        setFilteredProfiles(profiles);

        if (!debouncedSearchTerm) {
          const profileCount = await getProfilesCount();
          setProfileCount(profileCount.data().count);

          if (profileCount) {
            cursors.current.set(page + 1, profiles[profiles.length - 1].id);
          }
        } else {
          setProfileCount(profiles.length);
        }
      } catch (error) {
        console.error("Failed to fetch profiles", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [debouncedSearchTerm, page, getProfilesCount]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const onPageChanged = (nextPage: number) => {
    setPage(nextPage);
  };

  return (
    <>
      <div className="w-full">
        <input
          type="text"
          placeholder="Search by email, phone number, or ID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="input-bordered input w-full"
        />
        <table className="mt-4 table w-full">
          <thead>
            <tr>
              <th className="rounded-tr lg:rounded-tr-none">ID</th>
              <th className="hidden lg:table-cell">Full name</th>
              <th className="hidden lg:table-cell">Email</th>
              <th className="hidden lg:table-cell">Phone Number</th>
              <th className="hidden lg:table-cell">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Loading...
                </td>
              </tr>
            ) : (
              <>
                {filteredProfiles.map((profile) => (
                  <tr key={profile.id}>
                    <td>
                      <p className="text-sm md:text-base">{profile.id}</p>
                      <div className="lg:hidden">
                        <div className="text-lg font-bold">
                          {profile.name} {profile.lastName}
                        </div>
                        {profile.email && (
                          <div>
                            <p className="text-sm">
                              <b className="text-base">Email:</b> {profile.email}
                            </p>
                          </div>
                        )}
                        {profile.phoneNumber && (
                          <div>
                            <b>Phone:</b> {profile.phoneNumber}
                          </div>
                        )}
                        <div className="mt-6 flex justify-end">
                          <Link
                            to={routeNames["profiles.show"](profile.id)}
                            className="btn-outline btn-sm btn"
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="hidden lg:table-cell">
                      {profile.name} {profile.lastName}
                    </td>
                    <td className="hidden lg:table-cell">{profile.email}</td>
                    <td className="hidden lg:table-cell">{profile.phoneNumber}</td>
                    <td className="hidden lg:table-cell">
                      <Link
                        to={routeNames["profiles.show"](profile.id)}
                        className="btn-outline btn-sm btn"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        <div className="mt-4">
          <TablePagination
            currentPage={page}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={onPageChanged}
            recordsCount={profileCount}
          />
        </div>
      </div>
    </>
  );
};
