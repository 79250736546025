import { Invoice } from "../../../interfaces/invoice.interface";
import Card from "../../../components/shared/Card";
import { formattedDate } from "../../../utils/dateTime";
import { formattedCurrency } from "../../../utils/currency";
import { useEffect, useState } from "react";
import { ProfileService } from "../../../services";
import { Profile } from "../../../types";
import { ProfileLink } from "../../../components/shared/Navigation/ProfileLink";

export const InvoiceBasicInfo = ({ invoice }: { invoice: Invoice }) => {
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    const fetchProfile = async (userId: string) => {
      const profile = await ProfileService.getById(userId);
      setProfile(profile);
    };

    if (invoice.userId) {
      fetchProfile(invoice.userId);
    }
  }, [invoice.userId]);

  return (
    <div className="mt-6 grid gap-4 md:grid-cols-2 xl:grid-cols-3">
      <Card title="Invoice Information">
        <div>Invoice Number: {invoice.serialNumber}</div>
        {invoice.status && <div>Status: {invoice.status}</div>}
        <div>Total Amount: {formattedCurrency(invoice.totalAmount)}</div>
        <div>Created At: {formattedDate(invoice.createdAt, "datetime")}</div>
        <div>Updated At: {formattedDate(invoice.updatedAt, "datetime")}</div>
        {invoice.issuedAt && <div>Issued At: {formattedDate(invoice.issuedAt, "datetime")}</div>}
        {invoice.expiresAt && <div>Expires At: {formattedDate(invoice.expiresAt, "datetime")}</div>}

        {profile && (
          <div className="mt-4">
            <div>
              Creator:{" "}
              <strong>
                <ProfileLink userId={profile.id} fullName={`${profile.name} ${profile.lastName}`} />
              </strong>
            </div>
            <div>Email: {profile.email}</div>
            <div>Phone Number: {profile.phoneNumber}</div>
          </div>
        )}
      </Card>
      <Card title="Business Information">
        <div>Name: {invoice.business.fullName}</div>
        <div>Email: {invoice.business.email}</div>
        <div>Phone: {invoice.business.phoneNumber}</div>
      </Card>
      <Card title="Customer Information">
        <div>Name: {invoice.customer.fullName}</div>
        <div>Email: {invoice.customer.email}</div>
        <div>Phone: {invoice.customer.phoneNumber}</div>
      </Card>
    </div>
  );
};
