import { BiArrowBack } from "react-icons/bi";
import { Link, To } from "react-router-dom";

export const BackLink = ({ to }: { to?: To }) => {
  const linkTo = to || "#";

  return (
    <Link to={linkTo} onClick={!to ? () => window.history.back() : undefined}>
      <BiArrowBack size={30} className="mr-2" />
    </Link>
  );
};
