import { useState } from "react";
import { callFirebaseFunction } from "../../../services/firebase";
import { PaymentLink } from "../../../interfaces/invoice.interface";
import { toCents } from "../../../utils/currency";

interface invoicePaymentLinkFormProps {
  invoiceId: string;
  invoiceName: string;
  onBack: () => void;
}

export const InvoicePaymentLinkForm = ({
  invoiceId,
  invoiceName,
  onBack,
}: invoicePaymentLinkFormProps) => {
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState(`${invoiceName} payment`);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!amount || !description) {
        throw new Error("Amount and description are required");
      }

      const response = await callFirebaseFunction<{
        success: boolean;
        paymentLink?: PaymentLink;
        error?: string;
      }>("invoicesCreatePaymentLink", {
        productName: description,
        amount: toCents(amount),
        invoiceId,
      });

      if (response.success) {
        window.location.reload();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Payment Link product description</span>
        </label>
        <input
          className="input-bordered input"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <label className="label">
          <span className="label-text-alt text-blue-500">
            This is the description that will appear to the customer
          </span>
        </label>
      </div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">Amount</span>
        </label>
        <input
          className="input-bordered input w-full"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
      </div>
      {error && <p className="text-center text-red-500">{error}</p>}
      <button type="submit" disabled={loading} className="btn-primary btn mt-6">
        {loading ? "Creating..." : "Create Payment Link"}
      </button>
      <button type="button" className="btn-secondary btn-link btn" onClick={onBack}>
        back
      </button>
    </form>
  );
};
