import { useParams } from "react-router-dom";
import { PageHeader } from "../../components/shared";
import { BackLink } from "../../components/shared/Navigation/BackLink";
import { useEffect, useState } from "react";
import { InvoiceService } from "../../services/invoice.service";
import { Invoice, PaymentLink } from "../../interfaces/invoice.interface";
import { InvoiceBasicInfo } from "./showPage/BasicInfo";
import InvoicesPaymentsLinkIndex from "./paymentLinks/InvoicePaymentLinksIndex";

const InvoicesShowPage = () => {
  const invoiceId = useParams()["id"];
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [paymentLinks, setPaymentLinks] = useState<PaymentLink[]>([]);

  useEffect(() => {
    const fetchInvoice = async (invoiceId: string) => {
      const invoice = await InvoiceService.getById({ invoiceId });
      setInvoice(invoice);
      setPaymentLinks(invoice.paymentLinks || []);
    };

    if (invoiceId) {
      fetchInvoice(invoiceId);
    }
  }, [invoiceId]);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center">
        <BackLink />
        <PageHeader>{invoice && invoice.name} details</PageHeader>
      </div>
      {invoice && (
        <>
          <InvoiceBasicInfo invoice={invoice} />
          <InvoicesPaymentsLinkIndex
            paymenLinks={paymentLinks}
            invoiceId={invoice.id}
            invoiceName={invoice.name}
          />
        </>
      )}
    </div>
  );
};

export default InvoicesShowPage;
