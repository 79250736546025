import { MomentInput } from "moment";
import { formattedDate } from "../utils/dateTime";

interface DateTimeDisplayProps {
  date: MomentInput;
  type?: "date" | "datetime";
}

const DateTimeDisplay: React.FC<DateTimeDisplayProps> = ({ date, type = "datetime" }) => {
  return <span>{formattedDate(date, type)}</span>;
};

export default DateTimeDisplay;
