import { useEffect, useRef, useState } from "react";
import { SubscriptionPayment } from "../../types/subscriptionPayment.type";
import { PaymentService } from "../../services";
import { TablePagination } from "../shared/Table/TablePagination"; // Import TablePagination component
import DateTimeDisplay from "../../ui/dateTimeDisplay";
import { formattedCurrency } from "../../utils/currency";

const PAYMENTS_PER_PAGE = 20;

const UserSubscriptionPaymentList = ({ userId }: { userId: string }) => {
  const [payments, setPayments] = useState<SubscriptionPayment[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentsCount, setPaymentsCount] = useState(0);
  const lastVisible = useRef<any>();

  useEffect(() => {
    const fetchSubscriptionPaymentsCount = async () => {
      const count = await PaymentService.getPaymentSubscriptionCount(userId);
      setPaymentsCount(count);
    };

    fetchSubscriptionPaymentsCount();
  }, [userId]);

  useEffect(() => {
    const fetchPayments = async () => {
      const { data, lastDoc } = await PaymentService.getSubscriptionPaymentsByUser(
        userId,
        PAYMENTS_PER_PAGE,
        lastVisible.current,
      );
      setPayments(data);
      lastVisible.current = lastDoc;
    };

    fetchPayments();
  }, [userId, currentPage]);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="overflow-x-auto">
      {payments.length === 0 ? (
        <p>No payments found.</p>
      ) : (
        <div>
          <table className="hidden min-w-full bg-white md:table">
            <thead>
              <tr>
                <th className="border-b py-2 px-4">Product</th>
                <th className="border-b py-2 px-4">Amount</th>
                <th className="border-b py-2 px-4">Status</th>
                <th className="border-b py-2 px-4">Provider</th>
                <th className="border-b py-2 px-4">Created At</th>
                <th className="border-b py-2 px-4">Expires At</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td className="border-b py-2 px-4">{payment.productId}</td>
                  <td className="border-b py-2 px-4">
                    {formattedCurrency(payment.amount)} {payment.currency}
                  </td>
                  <td className="border-b py-2 px-4">{payment.status}</td>
                  <td className="border-b py-2 px-4">{payment.provider}</td>
                  <td className="border-b py-2 px-4">
                    <DateTimeDisplay date={payment.createdAt} />
                  </td>
                  <td className="border-b py-2 px-4">
                    <DateTimeDisplay date={payment.expiresAt} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="md:hidden">
            {payments.map((payment) => (
              <div key={payment.id} className="mb-4 rounded-lg bg-white p-4 shadow-md">
                <div className="mb-2">
                  <strong>Product:</strong> {payment.productId}
                </div>
                <div className="mb-2">
                  <strong>Amount:</strong> {formattedCurrency(payment.amount)} {payment.currency}
                </div>
                <div className="mb-2">
                  <strong>Status:</strong> {payment.status}
                </div>
                <div className="mb-2">
                  <strong>Provider:</strong> {payment.provider}
                </div>
                <div className="mb-2">
                  <strong>Created At:</strong> <DateTimeDisplay date={payment.createdAt} />
                </div>
                <div className="mb-2">
                  <strong>Expires At:</strong> <DateTimeDisplay date={payment.expiresAt} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="mt-6">
        <TablePagination
          currentPage={currentPage}
          itemsPerPage={PAYMENTS_PER_PAGE}
          onPageChange={paginate}
          recordsCount={paymentsCount}
        />
      </div>
    </div>
  );
};

export default UserSubscriptionPaymentList;
