import { useEffect, useState } from "react";
import { BiArrowBack, BiImages } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { PostPhotoSlider, PostStatusButtons } from "../../../components/posts";
import { Map, PageHeader } from "../../../components/shared";
import { CategoriesService } from "../../../services/categories.service";
import { PostService } from "../../../services/post.service";
import { Category, Post, PostPrivateDossier, PostStatus, Profile } from "../../../types";

import { routeNames } from "../../../routes";
import { PostPrivateDossierService } from "../../../services/postPrivateDossier.service";
import { ProfileService } from "../../../services";
import { ProfileLink } from "../../../components/shared/Navigation/ProfileLink";

export const PostShowPage = () => {
  const postId = useParams()["id"];

  const [reload, setReload] = useState<boolean>(true);
  const [post, setPost] = useState<Post | null>();
  const [profile, setProfile] = useState<Profile>();
  const [category, setCategory] = useState<Category>();
  const [postPrivateDossier, setPostPrivateDossier] = useState<
    PostPrivateDossier | null | undefined
  >();
  const [postStatus, setPostStatus] = useState<PostStatus>();
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    const getPost = async () => {
      if (postId && reload) {
        const loadedPost = await PostService.getById(postId);

        setPost(loadedPost);
        setPostStatus(loadedPost?.status);
        setReload(false);
      }
    };

    getPost();
  }, [postId, post?.status, reload]);

  useEffect(() => {
    const getCategory = async () => {
      if (post) {
        setCategory(await CategoriesService.getById(post.categoryId));

        if (post.isPrivateDossierAvailable === true) {
          setPostPrivateDossier(await PostPrivateDossierService.getById(post.id));
        } else {
          setPostPrivateDossier(null);
        }
      }
    };

    const getProfile = async () => {
      if (post) {
        setProfile(await ProfileService.getById(post.userId));
      }
    };

    getCategory();
    getProfile();
  }, [post]);

  if (post === null) {
    return <div>Post not found</div>;
  }

  if (
    category === undefined ||
    post === undefined ||
    postPrivateDossier === undefined ||
    profile === undefined
  ) {
    return <div>Loading...</div>;
  }

  const fieldGroupHeader = (text: string, className: string | undefined) => {
    return <div className={`font-bold ${className}`}>{text}</div>;
  };

  const isPostImagesAvailable = post.imageUrls.length > 0;

  const afterImagesDelete = () => {
    setReload(true);
  };

  return (
    <>
      <div className="flex items-center md:items-end">
        <Link to={routeNames[`posts.index.${postStatus ?? "pending"}`]}>
          <BiArrowBack size={30} className="mr-2" />
        </Link>
        <div className="md:flex md:items-end">
          <PageHeader>{post.businessName}</PageHeader>
          <span className="ml-2">({category.name})</span>
          <div className="badge ml-2 mb-1">{postStatus}</div>
        </div>
      </div>
      {fieldGroupHeader("Service description", "mt-8")}
      <div className="mt-2">{post.description}</div>

      {fieldGroupHeader("User Information", "mt-8")}
      <div className="divide-y lg:w-1/2">
        <div className="flex py-2">
          <div className="flex-1">User</div>
          <div className="ml-2 flex-none">
            <ProfileLink userId={profile.id} fullName={`${profile.name} ${profile.lastName}`} />
          </div>
        </div>

        <div className="flex py-2">
          <div className="flex-1">Phone</div>
          <div className="ml-2 flex-none">{profile.phoneNumber}</div>
        </div>

        <div className="flex py-2">
          <div className="flex-1">Mail</div>
          <div className="ml-2 flex-none">{profile.email}</div>
        </div>
      </div>

      {post.isPrivateDossierAvailable && (
        <>
          {fieldGroupHeader("User ID", "mt-8")}
          <div className="lg:w-1/2">
            <div className="py-2">
              <div className="mt-2 gap-4 md:flex">
                <div className="flex-1">
                  <img
                    src={postPrivateDossier?.idFrontImageUrl}
                    className="rounded-md"
                    alt="Front side of ID"
                  />
                </div>
                <div className="mt-4 flex-1 md:mt-0">
                  <img
                    src={postPrivateDossier?.idBackImageUrl}
                    className="rounded-md"
                    alt="Back side of ID"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {fieldGroupHeader("Post Information", "mt-8")}
      <div className="divide-y lg:w-1/2">
        {post.isPrivateDossierAvailable && (
          <div className="flex py-2">
            <div className="flex-1">Type of business</div>
            <div className="ml-2 flex-none">
              {postPrivateDossier?.isCompanyRegistered ? "Company" : "Independent"}
            </div>
          </div>
        )}
        <div className="flex py-2">
          <div className="flex-1">Name of the company/service</div>
          <div className="ml-2 flex-none">{post.businessName}</div>
        </div>

        <div className="flex py-2">
          <div className="flex-1">Category</div>
          <div className="ml-2 flex-none">{category.name}</div>
        </div>

        <div className="flex py-2">
          <div className="flex-1">Status</div>
          <div className="badge ml-2 flex-none">{postStatus}</div>
        </div>
      </div>

      {post.hasInsurance !== undefined && (
        <>
          {fieldGroupHeader("License & Insurance", "mt-8")}
          <div className="divide-y lg:w-1/2">
            <div className="flex py-2">
              <div className="flex-1">Professional License No.</div>
              <div className="ml-2 flex-none">{post.businessLicense ?? "NA"}</div>
            </div>

            <div className="flex py-2">
              <div className="flex-1">Covered by General Liability Insurance?</div>
              <div
                className={`badge ml-2 flex-none ${
                  post.hasInsurance ? "badge-success" : "badge-error"
                }`}
              >
                {post.hasInsurance ? "yes" : "no"}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mt-8 flex items-center">
        {fieldGroupHeader("Portfolio & Location", "")}
        {isPostImagesAvailable && (
          <button className="btn-secondary btn-xs btn ml-2" onClick={() => setEditMode(!editMode)}>
            {editMode ? "Cancel" : "Edit images"}
          </button>
        )}
      </div>

      {isPostImagesAvailable ? (
        <PostPhotoSlider post={post} editMode={editMode} afterDelete={() => afterImagesDelete()} />
      ) : (
        <div className="alert alert-error mt-4 lg:w-1/2">
          <BiImages />
          This post has no uploaded images.
        </div>
      )}

      <div className="mt-6 divide-y lg:w-1/2">
        <div className="flex items-center py-2">
          <div className="flex-1">Address</div>
          <div className="ml-2 flex-none">
            <div className="flex-1">
              <div>
                <div>{post.addressMainText}</div>
                <div>{post.addressSecondaryText}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex py-2">
          <div className="flex-1">Interior</div>
          <div className="ml-2 flex-none">{post.internalNumber ?? "N/A"}</div>
        </div>

        <div className="flex py-2">
          <div className="flex-1">Moving distance</div>
          <div className="ml-2 flex-none">{post.coverageLimit} miles</div>
        </div>
      </div>

      {post.lat != null && post.lng != null && (
        <div className="my-4 xl:w-2/3">
          <Map lat={post.lat!} lng={post.lng!} />
        </div>
      )}

      <div className="my-8">
        <PostStatusButtons
          post={post}
          onStatusUpdated={(newStatus: PostStatus) => setPostStatus(newStatus)}
        />
      </div>
    </>
  );
};
