import axios from "axios";
import { TypeSenseProfile } from "../types/typesense/profile.interface";

export const searchProfile = async (searchTerm: string): Promise<TypeSenseProfile[]> => {
  const collection = process.env.REACT_APP_TYPESENSE_USER_COLLECTION;
  let queryBy = "name,email,phoneNumber,lastName";
  let infix = "off,off,fallback,off";

  const query = `?q=${searchTerm}&query_by=${queryBy}&sort_by=name:asc&infix=${infix}`;

  const headers = {
    "X-TYPESENSE-API-KEY": process.env.REACT_APP_TYPESENSE_API_KEY,
  };

  const results = await axios.get(
    `${process.env.REACT_APP_TYPESENSE_URL}/collections/${collection}/documents/search${query}`,
    {
      headers,
    },
  );

  return results.data.hits.map((hit: any) => {
    const document = hit.document;
    document.userId = document.id;

    return document as Partial<TypeSenseProfile>;
  });
};

export const getProfileById = async (id: string): Promise<TypeSenseProfile> => {
  const collection = process.env.REACT_APP_TYPESENSE_USER_COLLECTION;
  const headers = {
    "X-TYPESENSE-API-KEY": process.env.REACT_APP_TYPESENSE_API_KEY,
  };

  const result = await axios.get(
    `${process.env.REACT_APP_TYPESENSE_URL}/collections/${collection}/documents/${id}`,
    {
      headers,
    },
  );

  return result.data as TypeSenseProfile;
};
