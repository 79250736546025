export const DEFAULT_REFERRALS_FOR_REWARD = 5;

export const DB_COLLECTIONS = {
  PROMOTIONAL_BANNERS: "promotionalBanners",
};

// Taken from CF project
export const MINIMUM_KNOCK_COST_FOR_PREFFERED_PROVIDER = 500;

export const DEFAULT_ITEMS_PER_PAGE = 20;
